import { useEffect, useState } from "react";
import { useParams } from 'react-router';
import useAxios from "../utils/useAxios";
import BootstrapTable from 'react-bootstrap-table-next';
import Button from 'react-bootstrap/Button';
import Row from "react-bootstrap/esm/Row";
import Col from "react-bootstrap/Col";
import moment from 'moment';
import paginationFactory from 'react-bootstrap-table2-paginator';
import ToolkitProvider, { Search } from 'react-bootstrap-table2-toolkit/dist/react-bootstrap-table2-toolkit';

const { SearchBar } = Search;

function DateFormatter(cell, row) {
    return (
        <span>{moment(cell).format('DD.MM.YYYY h:mm:ss')} UTC</span>
    );
}

function PacketSamplesPage() {
  const { id } = useParams();
  const [res, setRes] = useState([]);
  const api = useAxios();
  const columns = [{
      dataField: 'timestamp',
      text: 'Time (UTC)',
      formatter: DateFormatter,
      sort: true,
  },
  {
      dataField: 'src_ip',
      text: 'Source IP',
      sort: true,
  },
  {
      dataField: 'dst_ip',
      text: 'Destination IP',
      sort: true,
  },
  {
      dataField: 'src_port',
      text: 'Source Port',
      sort: true,
  },
  {
      dataField: 'dst_port',
      text: 'Destination Port',
      sort: true,
  },
  {
      dataField: 'len_ip',
      text: 'IP Packet Size',
      sort: true,
  },
  {
      dataField: 'len_payload',
      text: 'IP Payload Size',
      sort: true,
  }];

  const CustomExportCSVButton = (props) => {
    const handleClick = () => {
      props.onExport();
    };
    return (
      <div>
        <Button onClick={ handleClick }>Export to CSV</Button>
      </div>
    );  
  }

  useEffect(() => {
    const fetchData = async () => {
      try {
        const response = await api.get("/attacks/samples/" + id);
        setRes(response.data.samples);
      } catch {
        setRes([]);
      }
    };
    fetchData();
    // eslint-disable-next-line react-hooks/exhaustive-deps
  }, []);

  if (res.length == 0) {
    return (
      <Row>
        <Col>
          <h1 className="text-center">Packet Samples for Attack {id}</h1>
          <div className="text-center">There are no packet samples.</div>
        </Col>
      </Row>
    )
  }

  return (
    <Row>
      <Col>
      <h1 className="text-center">Packet Samples for Attack {id}</h1>
      <ToolkitProvider
        keyField="id"
        data={ res }
        columns={ columns }
        bootstrap4={ true }
        exportCSV
        search
        >
        {
            props => (
            <div>
                <SearchBar { ...props.searchProps } />
                <BootstrapTable { ...props.baseProps } pagination={ paginationFactory() } />
                <CustomExportCSVButton { ...props.csvProps } variant="info" />
            </div>
            )
        }
        </ToolkitProvider>   
      </Col>   
    </Row>
  );
}

export default PacketSamplesPage;
