import Row from "react-bootstrap/esm/Row";
import Col from "react-bootstrap/Col";
import Button from 'react-bootstrap/Button';
import {LinkContainer} from 'react-router-bootstrap'

const HomePage = () => {
  return (
    <Row className="justify-content-md-center text-center" style={{marginTop: "150px"}}>
      <Col sm="auto">
        <h1>Welcome to your <span className="logo-text">01T</span> DDoS Portal</h1>
        <p>Here you can review any recent DDoS attacks and their associated packet samples</p>
        <p>
          <LinkContainer to="/attacks">
            <Button>See Recent Attacks</Button>
          </LinkContainer>
        </p>
      </Col>
    </Row>
  );
};

export default HomePage;
