import Button from 'react-bootstrap/Button';

const Footer = () => {
  return (
    <div className="text-center"><hr />
      <h4>Created By <span className="logo-text">01T</span></h4>
      </div>
  );
};

export default Footer;
