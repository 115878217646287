import { useContext } from "react";
import AuthContext from "../context/AuthContext";
import Nav from 'react-bootstrap/Nav';
import Navbar from 'react-bootstrap/Navbar';
import {LinkContainer} from 'react-router-bootstrap'

import UserInfo from "../components/UserInfo";


function NavBar() {
  const { user } = useContext(AuthContext);
  return (
    <Navbar variant="dark" expand="lg">
      <Navbar.Brand href="/"><b><span className="logo-text">01T</span> DDoS Portal</b></Navbar.Brand>
      <Navbar.Toggle aria-controls="basic-navbar-nav" />
      <Navbar.Collapse id="basic-navbar-nav">
        <Nav className="me-auto">
        {user ? (
          <>
            <LinkContainer to="/">
              <Nav.Link>Home</Nav.Link>
            </LinkContainer>
            <LinkContainer to="/attacks">
              <Nav.Link>Attacks</Nav.Link>
            </LinkContainer>
          </>
        ) : (
          <>
            <LinkContainer to="/login">
              <Nav.Link>Login</Nav.Link>
            </LinkContainer>
          </>
        )}
        </Nav>
        {user && (
        <>
          <Nav className="ml-auto">
            <UserInfo user={user} />
          </Nav>
        </>
        )}
      </Navbar.Collapse>
  </Navbar>
  
  );
}
export default NavBar;
