import logo from './logo.svg';
import { BrowserRouter as Router, Route, Switch, Link } from "react-router-dom";
import Container from 'react-bootstrap/Container';
import Row from 'react-bootstrap/esm/Row';
import Col from "react-bootstrap/Col";
import PrivateRoute from "./utils/PrivateRoute";
import { AuthProvider } from "./context/AuthContext";
import LoginPage from "./views/LoginPage";
import PacketSamplesPage from './views/PacketSamplesPage';
import AttacksPage from './views/AttacksPage';
import HomePage from './views/HomePage';
import Footer from "./components/Footer";
import NavBar from './components/Navbar';
import './App.css';


function App() {
  return (
    <Router>
        <AuthProvider>
          <NavBar />
          <Container style={{paddingTop: '20px'}}>
            <Switch>
              <PrivateRoute path="/attacks" component={AttacksPage} />
              <PrivateRoute path="/samples/:id" component={PacketSamplesPage} />
              <Route path="/login" component={LoginPage} />
              <PrivateRoute exact path="/" component={HomePage} />
            </Switch>
          <Row className="justify-content-md-center">
            <Col>
            <Footer />
            </Col>
          </Row>
          </Container>
        </AuthProvider>
    </Router>
  );
}

export default App;
