import { useEffect, useState } from "react";
import useAxios from "../utils/useAxios";
import Button from 'react-bootstrap/Button';
import Row from "react-bootstrap/esm/Row";
import Col from "react-bootstrap/Col";
import {LinkContainer} from 'react-router-bootstrap'
import moment from 'moment';
import BootstrapTable from 'react-bootstrap-table-next';
import paginationFactory from 'react-bootstrap-table2-paginator';
import ToolkitProvider, { Search } from 'react-bootstrap-table2-toolkit/dist/react-bootstrap-table2-toolkit';

const { SearchBar } = Search;

function DateFormatter(cell, row) {
  return (
      <span>{moment(cell).format('YYYY-MM-DD HH:mm:ss')}</span>
  );
}

const rowClasses = row => (row.ongoing ? "table-danger" : "");

function AttacksPage() {
  const [res, setRes] = useState([]);
  const api = useAxios();

  const columns = [{
    dataField: 'start_time',
    text: 'Start Time (UTC)',
    formatter: DateFormatter,
    sort: true,
  },
  {
    dataField: 'duration',
    text: 'Duration',
    sort: true,
  },
  {
    dataField: 'ip_address',
    text: 'IP Address',
    sort: true,
    style: {'white-space': 'nowrap'},
  },
  {
    dataField: 'type',
    text: 'Attack Type',
    sort: true,
  },
  {
    dataField: 'action',
    text: 'Action',
    sort: true,
  },
  {
    dataField: 'id',
    text: 'Attack ID',
    sort: true,
  },
  {
    dataField: '',
    text: 'User Actions',
    formatter: (cell, row, rowIndex, formatExtraData) => {
      return (
        <div>
          <LinkContainer to={"/samples/" + row.id}>
            <Button>Packet Samples</Button>
          </LinkContainer>
        </div>
      );
    }
  }]

  const CustomExportCSVButton = (props) => {
    const handleClick = () => {
      props.onExport();
    };
    return (
      <div>
        <Button onClick={ handleClick }>Export to CSV</Button>
      </div>
    );  
  }


  useEffect(() => {
    const fetchData = async () => {
      try {
        const response = await api.get("/attacks/");
        setRes(response.data);
      } catch {
        setRes("Unable to download attacks list");
      }
    };
    fetchData();
    // eslint-disable-next-line react-hooks/exhaustive-deps
  }, []);

  if (res.length == 0) {
    return (
      <Row>
        <Col>
          <h1 className="text-center">Recent DDoS Attacks</h1>
          <div className="text-center">No attacks could be found towards your IP prefixes.</div>
        </Col>
      </Row>
    )
  }

  return (
    <Row>
      <Col sm="12">
      <h1 className="text-center">Recent DDoS Attacks</h1>
      <ToolkitProvider
        keyField="id"
        data={ res }
        columns={ columns }
        bootstrap4={ true }
        exportCSV
        search
        >
        {
            props => (
            <div>
                <SearchBar { ...props.searchProps } />
                <BootstrapTable { ...props.baseProps } rowClasses={rowClasses} pagination={ paginationFactory() } />
                <CustomExportCSVButton { ...props.csvProps } />
            </div>
            )
        }
        </ToolkitProvider>      
      </Col>
    </Row>
  );
}

export default AttacksPage;
