import { useContext } from "react";
import Button from 'react-bootstrap/Button';
import AuthContext from "../context/AuthContext";

function UserInfo({ user }) {
  const { logoutUser } = useContext(AuthContext);
  return (
    <div>
      Logged in as <b>{user.username}</b>&nbsp;
      <Button onClick={logoutUser} block>Logout</Button>
    </div>
  );
}

export default UserInfo;
